@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans"), local("OpenSans"),
    url("../assets/fonts/opensans.ttf") format("truetype");
}
@font-face {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 400;
  src: local("Oswald"), local("Oswald"),
    url("../assets/fonts/oswald.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto"),
    url("../assets/fonts/roboto.ttf") format("truetype");
}
@font-face {
  font-family: "Crimson Pro";
  font-style: normal;
  font-weight: 400;
  src: local("Crimson Pro"), local("CrimsonPro"),
    url("../assets/fonts/crimsonpro.ttf") format("truetype");
}
@font-face {
  font-family: "DM Serif Display";
  font-style: normal;
  font-weight: 400;
  src: local("DM Serif Display"), local("DM Serif Display"),
    url("../assets/fonts/dmserif.ttf") format("truetype");
}

* {
  margin: 0;
  padding: 0;
  text-align: center;
  box-sizing: border-box;
  font-family: "Open Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

section {
  padding: 0 10%;
  overflow: hidden;
}

@media (max-width: 768px) {
  section {
    padding: 0 5%;
    overflow: hidden;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  background: white;
  /* margin-top: constant(safe-area-inset-top);
  margin-top: env(safe-area-inset-top); */
}

/* ion-modal {
  margin-top: constant(safe-area-inset-top);
  margin-top: env(safe-area-inset-top);
} */

.example {
  display: flex;
  flex-direction: column;
}

/* @supports (-webkit-touch-callout: none) {
  .sc-ion-modal-md-h * {
    overflow-y: auto !important;
  }
}

@media (min-height: 700px) {
  .sc-ion-modal-md-h * {
    overflow-y: initial !important;
  }
  .sc-ion-modal-ios-h * {
    overflow-y: initial !important;
  }
} */
ion-modal.fullscreen {
  --width: 100%;
  --height: 100%;
  --border-radius: 0;
}

.sc-ion-modal-md-h {
  height: 100vh;
}


